// src/components/ProjectCard.js

import React, { useState } from 'react';
import './ProjectCard.css';

const ProjectCard = ({ title, imageSrc, description, extraText, url, buttonLabel }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={`project-card ${isExpanded ? 'expanded' : ''}`}
      onClick={toggleExpand}
    >
      <img src={imageSrc} alt={title} className="project-image" />
      <h3 className="project-title">{title}</h3>
      <p className="project-description">{description}</p>
      {isExpanded && (
        <div
          className="project-extra"
          dangerouslySetInnerHTML={{ __html: extraText }}
        />
      )}
      <a href={url} className="project-button" target="_blank" rel="noopener noreferrer">
        {buttonLabel}
      </a>
    </div>
  );
};

export default ProjectCard;
