// src/pages/Home.js

import React from 'react';
import Hero from '../components/Hero';
import FeaturedSectionAI from '../components/FeaturedSectionAI';
import FeaturedSectionContent from '../components/FeaturedSectionContent';
import FeaturedSectionCloud from '../components/FeaturedSectionCloud';
import Partner from '../components/Partner';
import Separator from '../components/Separator';
import './Home.css'; // Import styles for the Home page



const Home = () => {
  const partners = [
    { imageSrc: '/assets/TaffyMedia.jpg', name: 'TaffyMedia' },
    { imageSrc: '/assets/SBU.png', name: 'Shahid Beheshti University (SBU)' },
    { imageSrc: '/assets/shoreham.jfif', name: 'Shoreham Clinic' },
    { imageSrc: '/assets/ndatalyze.jfif', name: 'nDatalyze Co.' },
    { imageSrc: '/assets/Lntc.png', name: 'Lotus Nanotechnology Co. (LNTC)' },
    { imageSrc: '/assets/JPC.jpg', name: 'Jam Petrochemical Co. (JPC)' },
    { imageSrc: '/assets/Cova.png', name: 'Cova Construction Co.' },
    { imageSrc: '/assets/York.png', name: 'York University' },
  ];
  return (
    <div>
      <Hero />
      <FeaturedSectionAI />
      <Separator imageSrc="/assets/Separator.png" />
      <FeaturedSectionContent />
      <Separator imageSrc="/assets/Separator.png" />
      <FeaturedSectionCloud />
      <Separator imageSrc="/assets/Separator.png" />
      <div className="partners-section">
        <h1 className="partners-header">Join Our Growing List of Partners and Clients</h1>
        <div className="partners-list">
          {partners.map((partner, index) => (
            <Partner key={index} imageSrc={partner.imageSrc} name={partner.name} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
