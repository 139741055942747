// src/pages/About.js

import React from 'react';
import { Helmet } from 'react-helmet';
import PersonCard from '../components/PersonCard';
import './About.css'; // Ensure you import your CSS for styling

const About = () => {
  const teamMembers = [
    {
      imageSrc: '/assets/Amir.bmp',
      name: 'Amir Zarie',
      role: 'Co-founder, CEO',
      skills: [
        {
          name: 'AI & ML Operations',
          subSkills: ['Vertex AI', 'Large Language Models', 'Natural Language Processing'],
        },
        {
          name: 'Cloud Specialist',
          subSkills: ['AWS', 'Google Cloud', 'Analytics'],
        },
        {
          name: 'Project Management',
          subSkills: ['Agile', 'Scrum', 'Kanban'],
        },
      ],
    },
    {
      imageSrc: '/assets/Saleh.png',
      name: 'Saleh Zarei',
      role: 'Co-founder, CTO',
      skills: [
        {
          name: 'R&D',
          subSkills: ['Innovation', 'Prototyping', 'Research', 'Business Intelligence'],
        },
        {
          name: 'Writing',
          subSkills: ['Technical Writing', 'Copywriting', 'Content Creation'],
        },
        {
          name: 'Dynamic & 3D Website Development',
          subSkills: ['React', 'Node.js', 'ThreeJS', 'Fiber'],
        },
      ],
    },
    {
      imageSrc: '/assets/MohamadHoseinZarei.jpg',
      name: 'Mohamad Hosein Zarei',
      role: 'CFO, Legal Advisor',
      skills: [
        {
          name: 'Legal Counselling',
          subSkills: ['Contract Generation', 'Contract Editing'],
        },
        {
          name: 'Professor of Law at Shahid Beheshti University',
          subSkills: ['Public Law', 'Private Law', 'International Law'],
        },
        {
          name: 'Business Administration',
          subSkills: ['Legal and Financial Supervision', 'Business Strategy'],
        },
      ],
    },
    {
      imageSrc: '/assets/Kimiya.png',
      name: 'Kimiya Alinejad',
      role: 'Co-founder, Web Developer',
      skills: [
        {
          name: 'Static Website Development',
          subSkills: ['HTML', 'CSS', 'JavaScript', 'Squarespace', 'Wordpress'],
        },
        {
          name: 'Content Creation',
          subSkills: ['Adobe Illustrator', 'Adobe Aftereffects'],
        },
      ],
    },
    {
      imageSrc: '/assets/Mahyar.jpg',
      name: 'Mahyar Zarei',
      role: 'Co-founder, UX, Design',
      skills: [
        {
          name: 'User Interface Design',
          subSkills: ['Figma', 'Sketch', 'Adobe XD'],
        },
        {
          name: 'Content Creation',
          subSkills: ['Video Editing', 'Graphic Design', 'Animation'],
        },
      ],
    },
    {
      imageSrc: '/assets/Reyhane.jpg',
      name: 'Reyhane Jamshidian',
      role: 'Co-founder, UX, Design',
      skills: [
        {
          name: 'User Experience',
          subSkills: ['User Research', 'Wireframing', 'Prototyping'],
        },
        {
          name: 'Design',
          subSkills: ['Illustration', 'Branding', 'Typography'],
        },
      ],
    },
  ];

  const introSectionStyle = {
    backgroundImage: 'url(/assets/FooterBack.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '40px',
    marginBottom: '40px',
    borderRadius: '8px',
    color: 'white',
    textAlign: 'center',
  };

  return (
    <div className="about-page">
      <Helmet>
        <title>About Us | SkyCorals - Leaders in AI & Web Development</title>
        <meta
          name="description"
          content="Learn about SkyCorals, a pioneering team in AI, ML, digital transformation, and web development. Meet our experts who drive innovation and success."
        />
      </Helmet>
      <div className="intro-section" style={introSectionStyle}>
        <div className="intro-content">
          <img src="/assets/Logo-Full.png" alt="Company Logo" className="intro-image" />
          <h2 className="intro-header">About SkyCorals</h2>
          <p className="intro-text">
            At SkyCorals, we are committed to delivering innovative solutions that empower businesses to achieve their goals.
            Our team of experts specializes in AI & ML operations, content creation, and web development, providing cutting-edge
            services tailored to your needs. With a passion for excellence and a focus on customer satisfaction, SkyCorals is
            your trusted partner for success in the digital age.
          </p>
        </div>
      </div>
      <h1 className="about-header">Our Team</h1> {/* Large header */}
      <div className="team-members">
        {teamMembers.map((member, index) => (
          <PersonCard
            key={index}
            imageSrc={member.imageSrc}
            name={member.name}
            role={member.role}
            skills={member.skills}
          />
        ))}
      </div>
    </div>
  );
};

export default About;
