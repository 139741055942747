// src/components/FeaturedSection.js


import React from 'react';
import { useNavigate } from 'react-router-dom';
import './FeaturedSection.css'; // Add styles for the section
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import ModelCloud from './ModelCloud'; // Import the Model component

const FeaturedSectionCloud = () => {
  const navigate = useNavigate(); // Hook to navigate programmatically

  const handleButtonClick = () => {
    navigate('/projects/cloud'); // Navigate to the AI Projects page
  };

  return (
    <div className="featured-section">
      <div className="model-container">
          <Canvas
                className="featured-canvas"
                style={{ height: '400px', width: '700px', margin: '1 auto' }} // Adjust size and center it
                camera={{ position: [0, 1, 2], fov: 50 }}
              >
                <ambientLight intensity={0.5} color="#0000ff"/>
                <directionalLight position={[0, 0, 5]} intensity={1} color="#0000ff"/>
                <ModelCloud />
                <OrbitControls enableZoom={false} enableRotate={false}/>
          </Canvas>
      </div>
      <div className="featured-content">
        <h1>Explore Our Fullstack & Cloud-based Applications</h1>
        <h3>Cloud-based Solutions</h3>
        <p>Our cloud-based solutions offer scalable, secure, and efficient ways to manage your business operations. By leveraging the power of cloud computing, we provide seamless integration and accessibility, ensuring your data and applications are always available, no matter where you are.</p>
        <h3>Full-stack Applications</h3>
        <p>From the front-end to the back-end, our full-stack application development services cover every aspect of building robust and efficient software. Our team of experts uses the latest technologies to deliver applications that meet your business needs and exceed your expectations.</p>
        <h3>Static and Dynamic Website Development</h3>
        <p>Whether you need a simple static site or a complex dynamic platform, we specialize in developing websites that are both visually appealing and functionally powerful. Our development process ensures your website is fast, responsive, and optimized for all devices.</p>
        <h3>User Experience</h3>
        <p>At the heart of our development process is a commitment to exceptional user experience. We design interfaces that are intuitive and engaging, ensuring that users have a seamless and enjoyable interaction with your applications. Our user-centered approach focuses on maximizing usability and satisfaction.</p>
        <button onClick={handleButtonClick}>Visit Our Cloud and Application Solutions</button>
      </div>
    </div>
  );
};

export default FeaturedSectionCloud;
