// src/components/Model.js

import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import { MeshNormalMaterial } from 'three'; // Correct import

const ModelAI = () => {
  const { scene } = useGLTF('/assets/hud_brain_hologram.glb'); // Replace with your GLTF model path
  const ref = useRef();

  // Rotate the model continuously
  useFrame(() => {
    if (ref.current) {
      ref.current.rotation.y += 0.002;
    }
  });
    // Apply a basic standard material to the model
    scene.traverse((child) => {
        if (child.isMesh) {
        child.material = new MeshNormalMaterial({
            color: 0xffffff,
            metalness: 0.5,
            roughness: 0.5,
        });
        }
    });
  return <primitive ref={ref} object={scene} scale={0.8} position={[0, -1, 0]} />;
};

export default ModelAI;
