// src/components/PersonCard.js

import React, { useState } from 'react';
import './PersonCard.css';

const PersonCard = ({ imageSrc, name, role, skills }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <div className={`person-card ${isExpanded ? 'expanded' : ''}`} onClick={toggleExpand}>
      <img src={imageSrc} alt={name} className="person-image" />
      <h3 className="person-name">{name}</h3>
      <p className="person-role">{role}</p>
      {isExpanded && (
        <ul className="person-skills">
          {skills.map((skill, index) => (
            <li key={index}>
              <strong>{skill.name}</strong>
              <ul>
                {skill.subSkills.map((subSkill, subIndex) => (
                  <li key={subIndex}>{subSkill}</li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PersonCard;
