// src/pages/Contact.js

import React from 'react';
import { Helmet } from 'react-helmet';
import './Contact.css'; // Import the CSS file for styling

const Contact = () => {
  const personnel = [
    {
      imageSrc: '/assets/Amir.bmp',
      name: 'Amir Zarie',
      role: 'Co-founder, CEO',
      email: 'zarieamir@gmail.com',
      phone: '+1(647)716 1788',
    },
    {
      imageSrc: '/assets/Saleh.png',
      name: 'Saleh Zarei',
      role: 'Co-founder, CTO',
      email: 'msalehzarei@gmail.com',
      phone: '+98(921)240 3725',
    },
  ];

  return (
    <div className="contact-page">
      <Helmet>
        <title>Contact Us | SkyCorals - Get in Touch with Our Team</title>
        <meta
          name="description"
          content="Reach out to SkyCorals for AI, ML, digital transformation, and web development services. Contact our team of experts for more information or to discuss your project."
        />
      </Helmet>
      <div
        className="contact-header-section"
        style={{
          backgroundImage: "url('/assets/FooterBack.png')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: '60px 20px',
          textAlign: 'center',
          color: 'white',
        }}
      >
        <h1 className="contact-header-title">Contact Us</h1>
      </div>

      <div className="contact-personnel-section">
        {personnel.map((person, index) => (
          <div className="person-card" key={index}>
            <img src={person.imageSrc} alt={person.name} className="person-image" />
            <h3 className="person-name">{person.name}</h3>
            <p className="person-role">{person.role}</p>
            <p className="person-contact">
              <strong>Email:</strong> <a href={`mailto:${person.email}`}>{person.email}</a>
            </p>
            <p className="person-contact">
              <strong>Phone:</strong> <a href={`tel:${person.phone}`}>{person.phone}</a>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Contact;
