// src/projects/AIProjects.js

import React from 'react';
import { Helmet } from 'react-helmet';
import Separator from '../components/Separator';
import './Projects.css'; // Import the CSS file
import ProjectCard from '../components/ProjectCard';
import './ProjectCard.css'; // Import the CSS file for styling
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import ModelAI from '../components/ModelAI'; // Import the Model component

const AIProjects = () => {
  const aiProjects = [
    {
      title: 'nDatalyze Predictor Model',
      imageSrc: '/assets/ndatalyze.jfif',
      description:
        'Objective: To develop a comprehensive assessment tool for depression based on behavioral and genetic data, and deploy it for commercial use.',
      extraText: `
        <strong>Data Integration and Feature Engineering:</strong> 
        The project began with the extraction of behavioral data from extensive academic research. This data provided a foundational assessment based on various behavioral indicators. To enhance the predictive power of our model, we integrated genetic data, adding a crucial dimension to the analysis. <br/><br/>
        Our team then performed advanced feature engineering to identify and construct the most relevant features from both behavioral and genetic datasets. This process involved meticulous selection and transformation of data to ensure that our machine learning models could accurately capture the complexities of depression.<br/><br/>
        
        <strong>Model Development and Optimization:</strong> 
        To effectively cluster and classify the data, we employed agglomerate clustering techniques. This approach allowed us to identify natural groupings within the data, providing a more nuanced understanding of the underlying patterns. <br/><br/>
        We then developed a stacked Random Forest Classifier model, a powerful ensemble learning technique that combines multiple decision trees to improve predictive accuracy. The model underwent rigorous hypertuning to optimize its parameters, ensuring maximum performance. <br/><br/>
        
        <strong>Performance Metrics:</strong> 
        Our optimized model achieved an impressive precision and F1 score of approximately 90%. These metrics are critical in the context of mental health assessments, where the accuracy and reliability of predictions can significantly impact clinical outcomes and treatment plans. <br/><br/>
        
        <strong>Deployment:</strong> 
        The final step was to deploy the model on Google App Engine, a robust full-stack platform that supports scalable and reliable applications. This deployment enables nDatalyze Labs to use the assessment tool for commercial purposes, providing clinicians and researchers with a powerful resource to assess depression in individuals.<br/><br/>
        
        <strong>Conclusion:</strong> 
        SkyCorals collaboration with nDatalyze Labs exemplifies our ability to integrate diverse data sources, apply sophisticated machine learning techniques, and deploy scalable solutions for real-world applications. Our commitment to precision and innovation ensures that our clients receive tools that are not only cutting-edge but also practical and impactful.<br/><br/>
        By leveraging the latest in AI and machine learning, SkyCorals continues to lead the way in transforming complex data into actionable insights, driving better outcomes in various domains, including mental health.
      `,
      url: 'https://ndatalyze.com/',
      buttonLabel: 'Visit nDatalyze Co.'
    }
  ];

  return (
    <>
      <Helmet>
        <title>AI Projects | SkyCorals - Pioneering AI & Machine Learning Solutions</title>
        <meta
          name="description"
          content="Explore SkyCorals' AI projects, including the nDatalyze Predictor Model. Learn how we leverage advanced AI and machine learning techniques to drive innovation and impact."
        />
      </Helmet>
      <div>
        <Canvas
          className="featured-canvas"
          style={{ height: '400px', width: '400px', margin: '0 auto' }} // Adjust size and center it
          camera={{ position: [-2, -1, 8], fov: 50 }}
        >
          <ambientLight intensity={0.5} color="#0000ff"/>
          <directionalLight position={[0, 0, 5]} intensity={1} color="#0000ff"/>
          <ModelAI />
          <OrbitControls enableZoom={false} enableRotate={false}/>
        </Canvas>
      </div>
      <div className="project-section">
        <div className="project-content">
          <h1>Elevating Intelligence and Innovation in AI and Machine Learning</h1>
          <p>
            SkyCorals is at the forefront of leveraging artificial intelligence to revolutionize business operations and decision-making. Our comprehensive suite of AI and machine learning services is designed to optimize and automate workflows, providing businesses with unparalleled efficiency and insight. Here’s how we do it:
          </p>
          <h3>Machine Learning Mastery</h3>
          <p>
            At SkyCorals, we harness the power of machine learning to transform raw data into actionable intelligence. Our team of experts designs and implements sophisticated machine learning models tailored to your specific needs. We utilize both single and stacked models to ensure the highest accuracy and performance, adapting to a wide range of applications from predictive analytics to customer behavior analysis.
          </p>
          <h3>Real-Time Hyper-tuning of Parameters</h3>
          <p>
            In the rapidly evolving landscape of AI, staying ahead requires continuous optimization. SkyCorals specializes in real-time hypertuning of parameters, enabling our models to dynamically adjust and improve their performance as new data is introduced. This approach ensures that your AI systems are always operating at peak efficiency, delivering consistent and reliable results.
          </p>
          <h3>Advanced Feature Engineering</h3>
          <p>
            Feature engineering is a critical component of effective machine learning, and at SkyCorals, we excel in this domain. Our experts meticulously select, modify, and create the best possible features from raw data, enhancing the predictive power of our models. Through advanced techniques and a deep understanding of your business, we ensure that every variable contributes meaningfully to the outcomes you desire.
          </p>
          <h3>Utilizing Vertex AI on Google Cloud and Other Platforms</h3>
          <p>
            SkyCorals leverages the robust capabilities of Vertex AI on Google Cloud, along with other leading AI platforms, to build and deploy scalable machine learning solutions. Vertex AI provides us with a unified platform that integrates data engineering, model training, and deployment, allowing us to streamline processes and accelerate time-to-value. Our expertise extends across various cloud environments, ensuring flexibility and compatibility with your existing infrastructure.
          </p>
          <h3>Integration and Customization</h3>
          <p>
            Understanding that each business has unique challenges and goals, SkyCorals offers customized AI and ML solutions. We integrate seamlessly with your existing systems, providing a tailored approach that aligns with your strategic objectives. From small-scale implementations to large enterprise projects, our solutions are designed to grow with your business, driving innovation and competitive advantage.
          </p>
          <h3>Continuous Improvement and Support</h3>
          <p>
            AI and machine learning are not one-time projects but ongoing journeys. SkyCorals is committed to continuous improvement, offering comprehensive support and maintenance services to ensure your systems evolve with your business. Our proactive approach to monitoring, updating, and refining your AI models ensures sustained performance and relevance in a fast-changing market.
          </p>
          <h4>
            Experience the future of intelligent business solutions with SkyCorals. Partner with us to unlock the full potential of AI and machine learning, and transform the way you work.
          </h4>
        </div>
      </div>
      <Separator imageSrc="/assets/Separator.png" />      
      <h1 className="project-title">Our AI & ML Projects</h1> {/* Add title here */}
      <div className="project-cards">
        {aiProjects.map((project, index) => (
          <ProjectCard
            key={index}
            title={project.title}
            imageSrc={project.imageSrc}
            description={project.description}
            extraText={project.extraText}
            url={project.url}
            buttonLabel={project.buttonLabel}
          />
        ))}
      </div>
    </>
  );
};

export default AIProjects;
