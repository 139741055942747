// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import Contact from './pages/Contact';

const App = () => {
  return (
    <Router>
      <Helmet>
        <title>SkyCorals | AI, ML, Digital Transformation & Web Development</title>
        <meta
          name="description"
          content="SkyCorals offers cutting-edge AI, ML, and digital transformation services, along with expert web development including static, dynamic, and 3D designs."
        />
      </Helmet>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/about"
            element={
              <>
                <Helmet>
                  <title>About Us | SkyCorals</title>
                  <meta
                    name="description"
                    content="Learn more about SkyCorals, a leader in AI, machine learning, digital transformation, and innovative web development solutions."
                  />
                </Helmet>
                <About />
              </>
            }
          />
          <Route
            path="/projects/*"
            element={
              <>
                <Helmet>
                  <title>Our Projects | SkyCorals</title>
                  <meta
                    name="description"
                    content="Explore the projects by SkyCorals, showcasing our expertise in AI, ML, LLM, NLP, and advanced web development, including 3D and dynamic designs."
                  />
                </Helmet>
                <Projects />
              </>
            }
          />
          <Route
            path="/contact"
            element={
              <>
                <Helmet>
                  <title>Contact Us | SkyCorals</title>
                  <meta
                    name="description"
                    content="Get in touch with SkyCorals for AI, machine learning, digital transformation, and web development services. Let's discuss your next project!"
                  />
                </Helmet>
                <Contact />
              </>
            }
          />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
