// src/components/Partner.js

import React from 'react';
import './Partner.css'; // Import styles for the partner component

const Partner = ({ imageSrc, name }) => {
  return (
    <div className="partner">
      <div className="partner-image">
        <img src={imageSrc} alt={name} />
      </div>
      <p className="partner-name">{name}</p>
    </div>
  );
};

export default Partner;
