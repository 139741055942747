// src/components/Header.js

import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom'; // Import useLocation
import './Header.css';

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation(); // Get the current location

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Close dropdown and menu on route change
  useEffect(() => {
    setDropdownOpen(false);
    setMenuOpen(false);
  }, [location]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="header">
      <div className="logo">
        <img src="/assets/logo.png" alt="SkyCorals Logo" className="logo-image" />
      </div>
      <nav className="navigation">
        <button className="hamburger" onClick={toggleMenu}>
          &#9776; {/* Hamburger icon using Unicode character */}
        </button>
        <ul className={`menu ${menuOpen ? 'open' : ''}`}>
          <li>
            <NavLink to="/" activeClassName="active" exact>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" activeClassName="active">
              About
            </NavLink>
          </li>
          <li className="dropdown" ref={dropdownRef}>
            <span onClick={toggleDropdown}>Projects</span>
            {dropdownOpen && (
              <ul className="dropdown-menu">
                <li>
                  <NavLink to="/projects/ai" activeClassName="active">
                    AI & ML
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/projects/content" activeClassName="active">
                    Content Creation
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/projects/cloud" activeClassName="active">
                    Cloud Applications
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
          <li>
            <NavLink to="/contact" activeClassName="active">
              Contact Us
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
