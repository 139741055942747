// src/pages/Projects.js

import React from 'react';
import { Helmet } from 'react-helmet';
import { Routes, Route } from 'react-router-dom';
import AIProjects from '../projects/AIProjects';
import ContentCreation from '../projects/ContentCreation';
import CloudApplications from '../projects/CloudApplications';

const Projects = () => {
  return (
    <div>
      <Helmet>
        <title>Our Projects | SkyCorals - AI, Content Creation, Cloud Applications</title>
        <meta
          name="description"
          content="Explore SkyCorals' diverse projects in AI, content creation, and cloud applications. See how we drive innovation and deliver cutting-edge solutions."
        />
      </Helmet>
      <h1>Our Projects</h1>
      <Routes>
        <Route
          path="ai"
          element={
            <>
              <Helmet>
                <title>AI Projects | SkyCorals - Innovative AI Solutions</title>
                <meta
                  name="description"
                  content="Discover SkyCorals' AI projects, showcasing our expertise in artificial intelligence, machine learning, and data-driven solutions."
                />
              </Helmet>
              <AIProjects />
            </>
          }
        />
        <Route
          path="content"
          element={
            <>
              <Helmet>
                <title>Content Creation Projects | SkyCorals - Creative Excellence</title>
                <meta
                  name="description"
                  content="Explore our content creation projects at SkyCorals, where creativity meets technology. See our work in technical writing, copywriting, and digital media."
                />
              </Helmet>
              <ContentCreation />
            </>
          }
        />
        <Route
          path="cloud"
          element={
            <>
              <Helmet>
                <title>Cloud Applications | SkyCorals - Scalable Cloud Solutions</title>
                <meta
                  name="description"
                  content="View our cloud application projects, demonstrating SkyCorals' expertise in scalable cloud solutions, AWS, Google Cloud, and more."
                />
              </Helmet>
              <CloudApplications />
            </>
          }
        />
      </Routes>
    </div>
  );
};

export default Projects;
