// src/components/Separator.js

import React from 'react';
import './Separator.css';

const Separator = ({ imageSrc }) => {
  return (
    <div className="separator">
      <img src={imageSrc} alt="Separator" className="separator-image" />
    </div>
  );
};

export default Separator;
