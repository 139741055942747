// src/components/Model.js

import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import { MeshNormalMaterial } from 'three'; // Correct import


const ModelContent = () => {
  const { scene } = useGLTF('/assets/decorative_typewriter.glb'); // Replace with your GLTF model path
  const ref = useRef();

  // Rotate the model continuously
  useFrame(() => {
    if (ref.current) {
      ref.current.rotation.y += 0.002;
    }
  });

  scene.traverse((child) => {
    if (child.isMesh) {
    child.material = new MeshNormalMaterial({
        color: 0xffffff,
        metalness: 0.5,
        roughness: 0.5,
    });
    }
});
  return <primitive ref={ref} object={scene} scale={0.09} position={[0, 0.2, 0]} />;
};

export default ModelContent;
