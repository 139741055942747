// src/components/Footer.js

import React from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink for navigation
import './Footer.css';
import footerBack from '../assets/FooterBack.png'; // Import image from src/assets

const Footer = () => {
  return (
    <footer
      className="footer"
      style={{ backgroundImage: `url(${footerBack})` }} // Use imported image
    >
      <div className="footer-content">
        <img src="/assets/Logo-Full.png" alt="Logo" className="footer-logo" />
        <div className="footer-center">
          <NavLink to="/contact" className="footer-button">
            Get In Touch
          </NavLink>
          <div className="footer-text">
            © Powered By SkyCorals Inc. 2024
          </div>
        </div>
        <img src="/assets/FooterImg.png" alt="Right Img" className="footer-right-image" />
      </div>
    </footer>
  );
};

export default Footer;
